import Vue from "vue";
import Vuex from "vuex";
import VuexORM from "@vuex-orm/core";
import User from "./models/User";
import File from "./models/File";
import Assignment from "./models/Assignment";

Vue.use(Vuex);

// Create a new instance of Database.
const database = new VuexORM.Database();

// Register Models to Database.
database.register(User);
database.register(File);
database.register(Assignment);

// Create Vuex Store and register database through Vuex ORM.
export default new Vuex.Store({
  plugins: [VuexORM.install(database)],
  state: {},
  mutations: {},
  actions: {},
  modules: {},
});
