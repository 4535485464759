





































import { Component, Vue, Prop } from "vue-property-decorator";
import { Statement } from "@/frontend/services/Simulator";
import { $tPrefix } from "../plugins/i18n";

const NOP_MODELS = ["TransientNopInstructionModel", "CachedNopFetchInstructionModel"];
const BIT_FLIP_MODELS = [
  "CachedSingleBitFlipInstructionModel",
  "TransientBitFlipInstructionModel",
  "TransientSingleBitFlipInstructionModel",
];

@Component
export default class SimulationOutput extends Vue {
  @Prop(Number)
  goal: number;

  @Prop(Array)
  statements: Statement[];

  @Prop(Boolean)
  simulationRunning: boolean;

  tLocal = $tPrefix("simulation_output");

  get nopStatements(): Statement[] {
    return this.statements.filter((statement) => NOP_MODELS.includes(statement.operation));
  }

  get bitFlipStatements(): Statement[] {
    return this.statements.filter((statement) => BIT_FLIP_MODELS.includes(statement.operation));
  }

  get panels(): [string, Statement[]][] {
    return [
      ["skipped_instructions", this.nopStatements],
      ["bit_flips", this.bitFlipStatements],
    ];
  }
}
