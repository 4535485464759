


























































































import { Component, Vue } from "vue-property-decorator";
import FileTree, { File as TreeFile } from "@/frontend/components/FileTree.vue";
import FileEditor from "@/frontend/components/FileEditor.vue";
import FileTabs from "@/frontend/components/FileTabs.vue";
import ConsoleOutput from "@/frontend/components/ConsoleOutput.vue";
import File from "@/frontend/store/models/File";
import Assignment from "@/frontend/store/models/Assignment";
import AuthenticationService from "@/frontend/services/AuthenticationService";
import { Statement } from "@/frontend/services/Simulator";
import { sortBy } from "lodash";
import { $tPrefix } from "../plugins/i18n";
import SimulationOutput from "@/frontend/components/SimulationOutput.vue";
import { ExecutionResultType } from "@/frontend/services/Engine";

@Component({
  components: {
    SimulationOutput,
    FileTree,
    FileEditor,
    FileTabs,
    ConsoleOutput,
  },
})
export default class Home extends Vue {
  $confetti!: {
    start(): void;
    stop(): void;
  };

  treeFiles: TreeFile[] = [];
  tabs: File[] = [];

  assignment: Assignment | null = null;
  activeFileId: string | null = null;
  activeLine: number | null = null;

  showLoader = false;

  didWin = false;

  tLocal = $tPrefix("home");

  get consoleMessages(): string[] {
    return this.assignment?.consoleOutput ?? [];
  }

  get simulationStatements(): Statement[] {
    return this.assignment?.simulationOutput ?? [];
  }

  get simulationRunning(): boolean {
    return this.assignment?.simulatorRunning ?? false;
  }

  get goal(): number | undefined {
    return this.assignment?.assignmentSpec.config.faultAmountLessThan;
  }

  get won(): boolean {
    return this.didWin;
  }

  set won(val: boolean) {
    this.didWin = val;
    if (val) {
      this.$confetti.start();
    } else {
      this.$confetti.stop();
    }
  }

  get assignmentTitle(): string {
    return this.assignment?.assignmentSpec.config.title ?? "";
  }

  get assignmentInstructions(): string {
    return this.assignment?.assignmentSpec.config.instructions ?? "";
  }

  created(): void {
    AuthenticationService.initialize();
    if (!this.$route.query.id) {
      alert("Missing assignment id");
    } else if (!AuthenticationService.authorized) {
      this.$router.replace("/authentication");
    } else {
      this.loadAssignment(Assignment.load(this.$route.query.id as string));
    }
  }

  updateFileContent(file: File, content: string): void {
    file.content = content;
    File.update({
      where: file.id as string,
      data: {
        content: content,
      },
    });
  }

  async loadAssignment(request: Promise<Assignment>): Promise<void> {
    this.showLoader = true;
    try {
      this.assignment = await request;
      this.treeFiles = this.getTreeFiles(this.assignment.getTopFiles());
      this.assignment.startUpFileIds.forEach((file) => this.fileSelected(file));
      this.activeFileId = this.assignment.startUpFileIds[0];
    } catch (e) {
      alert(`Failed to fetch assignment (${e})`);
    }
    this.showLoader = false;
  }

  fileSelected(fileId: string): void {
    const file = File.find(fileId);

    if (file) {
      if (this.activeFileId !== file.id) {
        this.activeLine = null;
      }
      this.activeFileId = file.id;

      // Add to tabs if not already present
      if (this.tabs.findIndex((tab) => tab.id === fileId) === -1) {
        this.tabs = [...this.tabs, file];
      }
    }
  }

  removeTab(fileId: string): void {
    const tabIndex = this.tabs.findIndex((tab) => tab.id === fileId);

    if (tabIndex > -1) {
      const tabs = this.tabs;
      tabs.splice(tabIndex, 1);
      this.tabs = tabs;

      // When closing the active tab
      if (fileId === this.activeFileId) {
        if (this.tabs.length === 0) {
          this.activeFileId = null;
        } else {
          if (this.tabs[0].id !== null) {
            this.fileSelected(this.tabs[0].id);
          }
        }
      }
    }
  }

  removeActiveLine(): void {
    this.activeLine = null;
  }

  private getTreeFiles(files: File[]): TreeFile[] {
    return sortBy(files, [(file) => !file.dir, (file) => file.name.toLowerCase()]).map((file) => {
      return {
        id: file.id,
        name: file.name,
        type: file.type,
        children: this.getTreeFiles(file.getChildFiles()),
      };
    });
  }

  async save(): Promise<void> {
    this.showLoader = true;
    try {
      await this.assignment?.save();
    } catch (e) {
      alert(`Failed to save assignment (${e})`);
    }
    this.showLoader = false;
  }

  async reset(): Promise<void> {
    if (!confirm("Are you sure you want to reset this assignment?")) {
      return;
    }

    this.stop();
    this.assignment = null;
    this.activeLine = null;
    this.tabs = [];

    await this.loadAssignment(Assignment.reset(this.$route.query.id as string));
  }

  async simulate(): Promise<void> {
    if (!this.assignment) {
      throw new Error("Can't simulate empty assignment");
    }
    try {
      const { result, amount } = await this.assignment.simulate();
      if (this.goal && result === ExecutionResultType.Ok && amount <= this.goal) {
        this.won = true;
      }
    } catch (e) {
      alert(`Failed to simulate assignment (${e})`);
    }
  }

  async verify(): Promise<void> {
    try {
      await this.assignment?.verify();
    } catch (e) {
      alert(`Failed to verify assignment (${e})`);
    }
  }

  stop(): void {
    this.assignment?.stop();
  }

  clickedStatement(statement: Statement): void {
    const file = File.getFileForPath(statement.sourceFile);
    if (file) {
      this.fileSelected(file.id as string);
      this.activeLine = statement.sourceLine;
    }
  }
}
