


















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { highlight, languages, Grammar } from "prismjs";
import { PrismEditor } from "vue-prism-editor";
import "prismjs/components/prism-c";
import "prismjs/components/prism-makefile";
import "prismjs/components/prism-python";

@Component({
  components: { PrismEditor },
})
export default class Editor extends Vue {
  @Prop(String)
  value: string;

  @Prop(String)
  type: string;

  @Prop(Number)
  activeLine: number | null;

  get binary(): boolean {
    return this.type === "bin";
  }

  @Watch("activeLine")
  onActiveLineChanged(val: number, oldVal: number): void {
    if (val !== oldVal) {
      this.$el.querySelector(".prism-editor__line-number.active")?.classList.remove("active");
    }

    this.setActiveLine(val);
  }

  mounted(): void {
    this.setActiveLine(this.activeLine);
  }

  private setActiveLine(val: number | null) {
    // Ensures the line numbers are rendered by prism
    this.$nextTick(() => {
      if (val !== -1 && val !== null) {
        const $el = this.$el.querySelectorAll(".prism-editor__line-number")[val - 1];

        if ($el) {
          $el.classList.add("active");
          this.setActiveLinePosition();
          // Ensures the line number is in the correct position
          setTimeout(() => {
            $el.scrollIntoView({ block: "center" });
          }, 10);
        }
      }
    });
  }

  removeActiveLine(): void {
    this.$emit("removeActiveLine");
  }

  handleScroll(): void {
    this.setActiveLinePosition();
  }

  setActiveLinePosition(): void {
    if (this.activeLine === null) {
      return;
    }

    const $lineNumber = this.$el.querySelector(".prism-editor__line-number.active") as HTMLElement;
    const $highlight = this.$refs.highlight as HTMLElement;
    if (!$lineNumber || !$highlight) {
      return;
    }

    const offset = $lineNumber.offsetTop - ($lineNumber.parentElement?.parentElement?.scrollTop ?? 0);
    $highlight.style.top = `${offset}px`;
  }

  highlighter(content: string): string {
    const grammar = this.getGrammar(this.type);
    if (grammar) {
      return highlight(content, grammar.rules, grammar.type);
    } else {
      return content;
    }
  }

  private getGrammar(type: string): { rules: Grammar; type: string } | null {
    switch (type) {
      case "c":
        return { rules: languages.c, type: "c" };
      case "mak":
        return { rules: languages.makefile, type: "makefile" };
      case "pyt":
        return { rules: languages.python, type: "python" };
      default:
        return null;
    }
  }
}
