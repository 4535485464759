import { io, Socket } from "socket.io-client";
import config from "@/shared/config";
import AuthenticationService from "./AuthenticationService";
import Assignment from "@/frontend/store/models/Assignment";

export enum ExecutionResultType {
  Ok = "OK",
  Failed = "FAILED",
  Stopped = "STOPPED",
}

export interface EngineCallbacks {
  onConnect(): void;
  onDisconnect(): void;
  onData(command: string): void;
  onFinish(result: ExecutionResultType): void;
}

export default class Engine {
  stop: () => void = () => null;

  public run(command: string, data: Blob, assignment: Assignment, handler: EngineCallbacks): void {
    const socket = io(config.urls.backend, {
      transports: ["websocket"],
      query: { token: AuthenticationService.token },
    });
    this.stop = function () {
      socket.emit("app:stop");
    };
    this.handleSocketConnections(socket, handler);
    this.startOnEngine(socket, command, data, assignment, handler);
    this.processResults(socket, handler);
    this.processFinish(socket, handler);
  }

  private handleSocketConnections(socket: Socket, handler: EngineCallbacks) {
    socket.once("connect", () => {
      socket.emit("app:request");
    });

    socket.once("disconnect", () => {
      handler.onDisconnect();
    });
  }

  private startOnEngine(socket: Socket, command: string, data: Blob, assignment: Assignment, handler: EngineCallbacks) {
    socket.on("app:given", async () => {
      handler.onConnect();
      socket.emit("app:chunk:start");
      for (let i = 0; i < data.size; i += 1024 * 10) {
        const chunk = data.slice(i, i + 1024 * 10);
        socket.emit("app:chunk", { chunk });
      }

      socket.emit("app:simulate", {
        command: command,
        id: assignment.id,
        assignmentIdentification: assignment.assignmentIdentification,
      });
    });
  }

  private processResults(socket: Socket, handler: EngineCallbacks) {
    socket.on("engine:result", (message: string) => {
      message.split("\n").forEach((line) => {
        line = line.trim();
        if (line.length > 0) {
          handler.onData(line);
        }
      });
    });
  }

  private processFinish(socket: Socket, handler: EngineCallbacks) {
    socket.on("engine:finish", (result: string) => handler.onFinish(result as ExecutionResultType));
  }
}
