const assignments: Assignment[] = [
  {
    identification: "Training.DSB.CLI",
    config: {
      files: "assignments/Training.DSB.CLI.zip",
      startUpFileIds: ["src/main.c"],
      faultAmountLessThan: 0,
      title: "FIsim: secure bootloader code",
      instructions: "Explore the codebase of the secure bootloader and find vulnerabilities (if applicable)",
    },
    simulator: {
      projectFolder: "/usr/src/app/fisim/Training.DSB.CLI/Content/Exercise2",
      workFolder: "/usr/src/app/fisim/Training.DSB.CLI",
      executable: "mono Training.DSB.CLI.exe",
      cleanCommand: "make -f Makefile.AArch32 clean",
      buildCommand: "make -f Makefile.AArch32 all",
      parameters: { simulate: "Exercise2 fault", verify: "Exercise2 verify" },
    },
  },
  {
    identification: "Training.DSB.CLI.50",
    config: {
      files: "assignments/Training.DSB.CLI.zip",
      startUpFileIds: ["src/main.c"],
      faultAmountLessThan: 50,
      title: "FIsim: Let's do some FI fixing (hard)",
      instructions: "Remove FI vulnerabilities from your code until there are less than 50 faults left",
    },
    simulator: {
      projectFolder: "/usr/src/app/fisim/Training.DSB.CLI/Content/Exercise2",
      workFolder: "/usr/src/app/fisim/Training.DSB.CLI",
      executable: "mono Training.DSB.CLI.exe",
      cleanCommand: "make -f Makefile.AArch32 clean",
      buildCommand: "make -f Makefile.AArch32 all",
      parameters: { simulate: "Exercise2 fault", verify: "Exercise2 verify" },
    },
  },
  {
    identification: "Training.DSB.CLI.100",
    config: {
      files: "assignments/Training.DSB.CLI.zip",
      startUpFileIds: ["src/main.c"],
      faultAmountLessThan: 235,
      title: "FIsim: Let's fix a FI vulnerability",
      instructions: "Remove a few FI vulnerabilities by implementing a secure coding pattern (e.g. double check)",
    },
    simulator: {
      projectFolder: "/usr/src/app/fisim/Training.DSB.CLI/Content/Exercise2",
      workFolder: "/usr/src/app/fisim/Training.DSB.CLI",
      executable: "mono Training.DSB.CLI.exe",
      cleanCommand: "make -f Makefile.AArch32 clean",
      buildCommand: "make -f Makefile.AArch32 all",
      parameters: { simulate: "Exercise2 fault", verify: "Exercise2 verify" },
    },
  },
  {
    identification: "Training.DSB.CLI.1",
    config: {
      files: "assignments/Training.DSB.CLI.zip",
      startUpFileIds: ["src/main.c"],
      faultAmountLessThan: 1,
      title: "FIsim: fix all FI vulnerabilities",
      instructions: "Remove vulnerabilities from your code until there are no faults left",
    },
    simulator: {
      projectFolder: "/usr/src/app/fisim/Training.DSB.CLI/Content/Exercise2",
      workFolder: "/usr/src/app/fisim/Training.DSB.CLI",
      executable: "mono Training.DSB.CLI.exe",
      cleanCommand: "make -f Makefile.AArch32 clean",
      buildCommand: "make -f Makefile.AArch32 all",
      parameters: { simulate: "Exercise2 fault", verify: "Exercise2 verify" },
    },
  },
  // {
  //   identification: "Engine",
  //   config: {
  //     files: "assignments/Engine.zip",
  //     faultAmountLessThan: 5,
  //     title: "Engine",
  //     instructions: "Remove vulnerabilities from your code until there are less than 5 left",
  //   },
  //   simulator: {
  //     projectFolder: "/usr/src/app/fisim/Engine/Content/Exercise2",
  //     workFolder: "/usr/src/app/fisim/Engine",
  //     executable: "mono FiSim.Engine.exe",
  //     cleanCommand: "make -f Makefile.AArch32 clean",
  //     buildCommand: "make -f Makefile.AArch32 all",
  //     parameters: { simulate: "Exercise2 fault", verify: "Exercise2 verify" },
  //   },
  // },
  {
    identification: "Pinata",
    config: {
      files: "assignments/Pinata.zip",
      startUpFileIds: ["stage1/secure_boot.c"],
      faultAmountLessThan: 1,
      title: "Pinata Secure Boot exercise",
      instructions: "Bonus exercise - analyze the Pinata bootloader codebase and remove all FI vulnerabilities",
    },
    simulator: {
      projectFolder: "/usr/src/app/fisim/Training.DSB.CLI.Pinata/Content/Pinata",
      workFolder: "/usr/src/app/fisim/Training.DSB.CLI.Pinata",
      executable: "mono Training.DSB.Cli.Pinata.exe",
      cleanCommand: "make clean",
      buildCommand: "make all",
      parameters: { simulate: "Pinata fault", verify: "Pinata verify" },
    },
  },
];

interface AssignmentConfig {
  files: string;
  startUpFileIds?: string[];
  faultAmountLessThan: number;
  title: string;
  instructions: string;
}

export interface SimulatorConfig {
  projectFolder: string;
  executable: string;
  workFolder: string;
  cleanCommand: string;
  buildCommand: string;
  parameters: Record<string, string>;
}

export interface Assignment {
  identification: string;
  config: AssignmentConfig;
  simulator: SimulatorConfig;
}

export class Assignments {
  public static get(identification: string): Assignment | undefined {
    return assignments.find((assignment) => assignment.identification == identification);
  }

  public static getOrFail(identification: string): Assignment {
    const assignment = Assignments.get(identification);
    if (!assignment) {
      throw new Error(`Unknown assignment: ${identification}`);
    }
    return assignment;
  }
}
