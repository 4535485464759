










import { Component, Vue, Prop } from "vue-property-decorator";
import File from "@/frontend/store/models/File";
import icons from "@/frontend/helpers/filetypeIcons";

@Component
export default class FileTabs extends Vue {
  icons = icons;

  @Prop(Array)
  tabs: File[];

  @Prop()
  activeFileId: string;

  get tabIndex(): number {
    const index = this.tabs.findIndex((tab) => tab.id === this.activeFileId);

    return index ?? -1;
  }

  setActiveFileId(index?: number): void {
    if (index !== undefined) {
      this.$emit("fileSelected", this.tabs[index].id);
    }
  }

  removeTab(fileId: string): void {
    this.$emit("removeTab", fileId);
  }
}
