import axios from "axios";
import JSZip from "jszip";
import Assignment from "@/frontend/store/models/Assignment";
import File from "@/frontend/store/models/File";
import { getFileType } from "@/shared/FileTypes";
import AuthenticationService from "./AuthenticationService";

export default class AssignmentStorage {
  async loadAssignment(assignment: Assignment): Promise<void> {
    const zip = await this.loadZip(assignment.assignmentUrl);
    const paths = this.getPaths(zip);
    await Promise.all([this.createDirectories(assignment, paths), this.createFiles(assignment, paths, zip)]);
  }

  async saveAssignment(assignment: Assignment, files: File[]): Promise<void> {
    const zip = await this.serializeAssignment(files);
    const formData = new FormData();
    formData.append("file", zip);
    await AuthenticationService.axios.post(`assignments/${assignment.id}/files`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  }

  async serializeAssignment(files: File[]): Promise<Blob> {
    const zip = new JSZip();
    files
      .filter((file) => file.content)
      .map((file) => {
        zip.file(file.path, file.content ?? "", {
          base64: file.type === "bin",
        });
      });
    return await zip.generateAsync({ type: "blob" });
  }

  private async loadZip(assignmentUrl: string): Promise<JSZip> {
    const response = await axios.get(assignmentUrl, { responseType: "arraybuffer" });
    const zip = new JSZip();
    await zip.loadAsync(response.data, { createFolders: true });
    return zip;
  }

  private getPaths(zip: JSZip): string[] {
    return zip
      .filter(
        (relativePath) =>
          !(relativePath.startsWith("__") || relativePath.startsWith(".") || relativePath.indexOf("/.") > 0)
      )
      .map((file) => file.name);
  }

  private async createDirectories(assignment: Assignment, paths: string[]): Promise<void> {
    const directories = paths
      .filter((path) => path.endsWith("/"))
      .map((path) => {
        const pathWithoutSlash = path.slice(0, -1);
        const name = pathWithoutSlash.substring(pathWithoutSlash.lastIndexOf("/") + 1);

        const parentId = pathWithoutSlash.substring(0, pathWithoutSlash.lastIndexOf("/")) || null;

        return {
          id: pathWithoutSlash,
          assignmentId: assignment.id,
          name,
          type: "dir",
          path,
          parentId: parentId,
        };
      });

    await File.insert({ data: directories });
  }
  private async pathToFile(path: string, zip: JSZip, assignment: Assignment) {
    const name = path.substring(path.lastIndexOf("/") + 1);
    const type = getFileType(name);
    const content = await zip.file(path)?.async(type === "bin" ? "base64" : "string");

    const parentId = path.substring(0, path.lastIndexOf("/")) || null;
    return {
      id: path,
      assignmentId: assignment.id,
      name: name,
      path,
      content,
      parentId,
      type,
    };
  }

  private async createFiles(assignment: Assignment, paths: string[], zip: JSZip): Promise<void> {
    const files = await Promise.all(
      paths.filter((path) => !path.endsWith("/")).map((path) => this.pathToFile(path, zip, assignment))
    );
    await File.insert({ data: files });
  }
}
