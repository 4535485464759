import axios, { AxiosInstance } from "axios";
import config from "@/shared/config";

export class AuthenticationService {
  public axios: AxiosInstance;

  public initialize(token?: string): void {
    if (token) {
      sessionStorage["token"] = token;
    }
    this.axios = axios.create({
      baseURL: `${config.urls.backend}api/`,
      headers: {
        Authorization: this.authorizationHeader,
      },
    });
  }

  public get authorized(): boolean {
    return sessionStorage["token"]?.length > 0;
  }

  public get token(): string {
    return sessionStorage["token"] ?? "undefined";
  }

  private get authorizationHeader(): string | undefined {
    return `Bearer ${this.token}`;
  }
}

const authenticationService = new AuthenticationService();

export default authenticationService;
