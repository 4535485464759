export default {
  // required
  txt: "mdi-file-document",
  bin: "mdi-file-image",
  c: "mdi-file-document-outline",
  mak: "mdi-wrench-outline",
  pyt: "mdi-file-document-outline",
  // extra
  html: "mdi-language-html5",
  js: "mdi-nodejs",
  json: "mdi-code-json",
  md: "mdi-language-markdown",
  pdf: "mdi-file-pdf",
  png: "mdi-file-image",
  xls: "mdi-file-excel",
};
