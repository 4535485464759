

























import { Component, Vue, Prop } from "vue-property-decorator";
import icons from "@/frontend/helpers/filetypeIcons";

export interface File {
  id: string | null;
  name: string;
  type: string | null;
  children: File[] | null;
}

@Component
export default class FileTree extends Vue {
  icons = icons;

  @Prop(Array)
  files?: File[];

  @Prop()
  activeFileId: string;

  @Prop(Array)
  initiallyOpen?: [];

  selected(selected: string[]): void {
    this.$emit("fileSelected", selected[0]);
  }

  get active(): string[] {
    return [this.activeFileId];
  }
}
