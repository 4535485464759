import { Model, Fields, Record } from "@vuex-orm/core";
import { AxiosResponse } from "axios";
import { count } from "rxjs/operators";
import File from "@/frontend/store/models/File";
import AssignmentStorage from "@/frontend/services/AssignmentStorage";
import Simulator, { Statement, Status as SimulatorStatus } from "@/frontend/services/Simulator";
import { RunMode, SimulatorRun } from "@/frontend/services/Simulator";
import AuthenticationService from "@/frontend/services/AuthenticationService";
import { ExecutionResultType } from "@/frontend/services/Engine";
import config from "@/shared/config";
import { Assignments, Assignment as AssignmentSpec } from "@/shared/Assignments";

enum ResultType {
  Verification = "VERIFICATION",
  Simulation = "SIMULATION",
}

export default class Assignment extends Model {
  static entity = "assignments";

  id: string | number | null;
  assignmentUrl: string;
  startUpFileIds: string[];
  assignmentIdentification: string;

  consoleOutput: string[];
  simulationOutput: Statement[];
  simulatorStatus: SimulatorStatus = SimulatorStatus.Idle;
  stopSimulator: (() => void) | null = null;

  constructor(record?: Record) {
    super(record);
    ({
      id: this.id = null,
      assignmentUrl: this.assignmentUrl = "",
      assignmentIdentification: this.assignmentIdentification = "",
      consoleOutput: this.consoleOutput = [],
      simulationOutput: this.simulationOutput = [],
      startUpFileIds: this.startUpFileIds = [],
    } = this);
  }

  static fields(): Fields {
    return {
      id: this.uid(),
      assignmentUrl: this.attr(null),
      assignmentIdentification: this.attr(null),
      consoleOutput: this.attr([]),
      simulationOutput: this.attr([]),
      startUpFileIds: this.attr([]),
    };
  }

  static async load(assignmentId: string | number): Promise<Assignment> {
    const assignmentData = await AuthenticationService.axios.get<Assignment>(
      `${config.urls.backend}api/assignments/${assignmentId}`
    );
    return Assignment.loadRemoteAssignmentData(assignmentData);
  }

  static async reset(assignmentId: string | number): Promise<Assignment> {
    const assignmentData = await AuthenticationService.axios.patch<Assignment>(
      `${config.urls.backend}api/assignments/${assignmentId}/reset`
    );
    return Assignment.loadRemoteAssignmentData(assignmentData);
  }

  static async loadRemoteAssignmentData(assignmentData: AxiosResponse<Assignment>): Promise<Assignment> {
    await Assignment.insert(assignmentData);
    const assignment = Assignment.find(assignmentData.data.id as string | number) as Assignment;
    const assignmentStorage = new AssignmentStorage();
    await assignmentStorage.loadAssignment(assignment);
    return assignment;
  }

  async save(): Promise<void> {
    if (this.id) {
      const assignmentStorage = new AssignmentStorage();
      const files = File.query().where("assignmentId", this.id).get();
      await assignmentStorage.saveAssignment(this, files);
    }
  }

  async verify(): Promise<void> {
    const simulator = new Simulator();
    const simulatorRun = simulator.runFiles(this, RunMode.Verify);
    this.handleSimulatorRun(simulatorRun);
    await this.handleVerifyResult(simulatorRun);
  }

  private async handleVerifyResult({ result$ }: SimulatorRun): Promise<void> {
    const result = await result$.toPromise();
    const completed = result === ExecutionResultType.Ok;
    await this.saveResult(ResultType.Verification, result, completed, null);
  }

  async simulate(): Promise<{ result: ExecutionResultType; amount: number }> {
    const simulator = new Simulator();
    const simulatorRun = simulator.runFiles(this, RunMode.Simulate);
    this.handleSimulatorRun(simulatorRun);
    const result = await this.handleSimulatorResult(simulatorRun);
    return { result, amount: this.simulationOutput.length };
  }

  private async handleSimulatorResult({ simulationOutput$, result$ }: SimulatorRun): Promise<ExecutionResultType> {
    const [result, statementCount] = await Promise.all([
      result$.toPromise(),
      simulationOutput$.pipe(count()).toPromise(),
    ]);
    const completed = result === ExecutionResultType.Ok;
    const errors = result === ExecutionResultType.Ok ? statementCount : null;
    await this.saveResult(ResultType.Simulation, result, completed, errors);
    return result;
  }

  private async saveResult(
    type: ResultType,
    execution: ExecutionResultType,
    completed: boolean,
    errors: number | null
  ): Promise<void> {
    await AuthenticationService.axios.post(`${config.urls.backend}api/assignments/${this.id}/results`, {
      type,
      execution,
      errors,
      completed,
    });
  }

  private handleSimulatorRun({ consoleOutput$, simulationOutput$, status$, stop }: SimulatorRun) {
    this.consoleOutput = [];
    this.simulationOutput = [];

    consoleOutput$.subscribe((output) => this.consoleOutput.push(output));
    simulationOutput$.subscribe((output) => this.simulationOutput.push(output));

    status$.subscribe((status) => (this.simulatorStatus = status));
    this.stopSimulator = stop;
  }

  stop(): void {
    if (typeof this.stopSimulator === "function") {
      this.stopSimulator();
    }
  }

  setSimulatorStatus(status: SimulatorStatus): void {
    this.simulatorStatus = status;
  }

  get simulatorRunning(): boolean {
    return this.simulatorStatus === SimulatorStatus.Busy;
  }

  get assignmentSpec(): AssignmentSpec {
    return Assignments.getOrFail(this.assignmentIdentification);
  }

  getTopFiles(): File[] {
    return File.query()
      .where((file: File) => {
        return file.assignmentId == this.id && file.parentId == null;
      })
      .get();
  }
}
