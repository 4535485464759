import { Model, Fields } from "@vuex-orm/core";
import axios from "axios";

export default class User extends Model {
  static entity = "users";

  static fields(): Fields {
    return {
      id: this.uid(),
      name: this.attr(""),
    };
  }

  static async fetch(): Promise<void> {
    const response = await axios.get("/api/users");
    await User.insert({ data: response.data });
  }
}
