import Vue from "vue";
import en from "../../locales/en.yml";
import nl from "../../locales/nl.yml";
import ja from "../../locales/ja.yml";
import VueI18n, { TranslateResult } from "vue-i18n";

Vue.use(VueI18n);

const messages = { en: en.root, nl: nl.root, ja: ja.root };

const i18n = new VueI18n({ locale: "en", messages });
export default i18n;
export const $tPrefix = (root: string) => (key: string, args?: Record<string, unknown>): TranslateResult => {
  return i18n.t(`${root}.${key}`, args);
};
