import Assignment from "@/frontend/store/models/Assignment";
import AssignmentStorage from "@/frontend/services/AssignmentStorage";
import File from "@/frontend/store/models/File";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import Engine, { EngineCallbacks, ExecutionResultType } from "./Engine";

const SIMULATOR_PREFICES = ["Transient", "Cached"];

export interface Statement {
  operation: string;
  result: string;
  sourceFile: string;
  sourceLine: number;
}

export interface SimulatorRun {
  consoleOutput$: Observable<string>;
  simulationOutput$: Observable<Statement>;
  status$: Observable<Status>;
  result$: Observable<ExecutionResultType>;
  stop: () => void;
}

interface EngineArgs {
  assignment: Assignment;
  consoleOutput$: ReplaySubject<string>;
  simulationOutput$: ReplaySubject<Statement>;
  status$: ReplaySubject<Status>;
  result$: Subject<ExecutionResultType>;
}
interface EngineRunner {
  assignment: Assignment;
  command: string;
  callbacks: EngineCallbacks;
  initMessage: string;
  consoleOutput$: ReplaySubject<string>;
  status$: ReplaySubject<Status>;
}

export enum Status {
  Idle,
  Busy,
  Stopped,
}

export enum RunMode {
  Simulate,
  Verify,
}

export default class Simulator {
  engine: Engine | null = null;
  aborted = false;
  status: Status = Status.Idle;

  runFiles(assignment: Assignment, mode = RunMode.Simulate): SimulatorRun {
    const consoleOutput$ = new ReplaySubject<string>();
    const simulationOutput$ = new ReplaySubject<Statement>();
    const status$ = new ReplaySubject<Status>();
    const result$ = new BehaviorSubject<ExecutionResultType>(ExecutionResultType.Failed);
    const stop = this.getStopFunction(consoleOutput$, status$);

    const engineArgs = {
      assignment,
      consoleOutput$,
      simulationOutput$,
      status$,
      result$,
    };

    // Run verification/simulation in background
    mode === RunMode.Simulate ? this.runSimulation(engineArgs) : this.runVerify(engineArgs);

    return {
      consoleOutput$,
      simulationOutput$,
      status$,
      result$,
      stop,
    };
  }

  private runSimulation({ assignment, consoleOutput$, simulationOutput$, status$, result$ }: EngineArgs): void {
    this.runEngine({
      assignment,
      command: "simulate",
      callbacks: this.createSimulationCallBacks(assignment, consoleOutput$, simulationOutput$, result$),
      consoleOutput$,
      status$,
      initMessage: "Starting simulation ...",
    });
  }

  private runVerify({ assignment, consoleOutput$, status$, result$ }: EngineArgs): void {
    this.runEngine({
      assignment,
      command: "verify",
      callbacks: this.createVerificationCallBacks(consoleOutput$, result$),
      consoleOutput$,
      status$,
      initMessage: "Starting verification ...",
    });
  }

  private createSimulationCallBacks(
    assignment: Assignment,
    consoleOutput$: ReplaySubject<string>,
    simulationOutput$: ReplaySubject<Statement>,
    result$: Subject<ExecutionResultType>
  ): EngineCallbacks {
    return {
      onConnect: () => consoleOutput$.next("Receiving output ..."),
      onDisconnect: () => {
        consoleOutput$.complete();
        simulationOutput$.complete();
        result$.complete();
      },
      onData: (message: string) => {
        if (SIMULATOR_PREFICES.some((prefix) => message.startsWith(prefix))) {
          this.registerStatement(assignment, simulationOutput$, message);
        } else {
          consoleOutput$.next(message);
        }
      },
      onFinish: (result) => {
        consoleOutput$.next(
          `Finished simulation ${result === ExecutionResultType.Ok ? "successfully" : "with errors"}`
        );
        result$.next(result);
      },
    };
  }

  private createVerificationCallBacks(
    consoleOutput$: ReplaySubject<string>,
    result$: Subject<ExecutionResultType>
  ): EngineCallbacks {
    return {
      onConnect: () => consoleOutput$.next("Receiving output ..."),
      onDisconnect: () => {
        consoleOutput$.complete();
        result$.complete();
      },
      onData: (message: string) => consoleOutput$.next(message),
      onFinish: (result) => {
        consoleOutput$.next(
          `Finished verification ${result === ExecutionResultType.Ok ? "successfully" : "with errors"}`
        );
        result$.next(result);
      },
    };
  }

  private setStatus(status: Status, status$: ReplaySubject<Status>) {
    this.status = status;
    status$.next(status);
  }

  private getStopFunction(consoleOutput$: ReplaySubject<string>, status$: ReplaySubject<Status>): () => void {
    return () => {
      if (this.status === Status.Stopped) {
        return;
      }

      this.engine?.stop();
      this.setStatus(Status.Stopped, status$);

      consoleOutput$.next("Simulation stopped");
    };
  }

  private async runEngine({ assignment, command, callbacks, initMessage, consoleOutput$, status$ }: EngineRunner) {
    this.setStatus(Status.Busy, status$);
    consoleOutput$.next(initMessage);

    const data = await this.zipSourceFiles(assignment);

    if (this.status === Status.Stopped) {
      return;
    }

    consoleOutput$.next("Connecting to simulator ...");

    this.engine = new Engine();
    const enrichedCallbacks: EngineCallbacks = {
      ...callbacks,
      onDisconnect: () => {
        this.setStatus(Status.Stopped, status$);
        return callbacks.onDisconnect();
      },
    };

    this.engine.run(command, data, assignment, enrichedCallbacks);
  }

  private async zipSourceFiles(assignment: Assignment): Promise<Blob> {
    const files = File.query().where("assignmentId", assignment.id).get();
    const assignmentStorage = new AssignmentStorage();
    return await assignmentStorage.serializeAssignment(files);
  }

  private registerStatement(
    assignment: Assignment,
    simulationOutput$: ReplaySubject<Statement>,
    message: string
  ): void {
    const simulator = assignment.assignmentSpec.simulator;
    const folder = simulator.projectFolder.substring(simulator.workFolder.length).replace(/^\//, "");
    // strip operation
    const firstSpaceIndex = message.indexOf(" ");
    const operation = message.substring(0, firstSpaceIndex);
    const remainder = message.substring(firstSpaceIndex + 1);

    // get result
    const lastSpaceIndex = remainder.lastIndexOf(" ");
    const result = remainder.substring(0, lastSpaceIndex);

    // get source info
    const sourceInfo = remainder.substring(lastSpaceIndex + 1);
    const colonIndex = sourceInfo.lastIndexOf(":");
    const sourceFile = sourceInfo.substring(0, colonIndex).substring(folder.length).replace(/^\//, "");
    const sourceLine = Number(sourceInfo.substring(colonIndex + 1));

    // register statement
    simulationOutput$.next({
      operation: operation,
      result: result,
      sourceFile: sourceFile,
      sourceLine: sourceLine,
    });
  }
}
