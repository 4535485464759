
















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { $tPrefix } from "../plugins/i18n";

@Component
export default class ConsoleOutput extends Vue {
  @Prop(Array)
  messages: string[];

  @Prop(Boolean)
  simulationRunning: boolean;

  tLocal = $tPrefix("console_output");

  @Watch("messages")
  onMessagesChanged(): void {
    this.scrollConsoleToBottom();
  }

  scrollConsoleToBottom(): void {
    this.$nextTick(() => {
      const container = this.$refs.consoleOutput as Element;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    });
  }
}
