import { Model, Fields, Record } from "@vuex-orm/core";
import Assignment from "@/frontend/store/models/Assignment";

export default class File extends Model {
  static entity = "files";

  id: string | null;
  parentId: string | null;
  assignmentId: number;
  name: string;
  path: string;
  type: string | null;
  content: string | null;
  parent: File | null;
  assignment: Assignment;

  constructor(record?: Record) {
    super(record);
    ({
      id: this.id = null,
      parentId: this.parentId = null,
      assignmentId: this.assignmentId = 0,
      name: this.name = "",
      path: this.path = "",
      type: this.type = null,
      content: this.content = null,
      parent: this.parent = null,
      assignment: this.assignment = new Assignment(),
    } = this);
  }

  static fields(): Fields {
    return {
      id: this.uid(),
      parentId: this.attr(null),
      assignmentId: this.attr(null),
      name: this.attr(null),
      path: this.attr(null),
      type: this.attr(null),
      content: this.attr(null),
      parent: this.belongsTo(File, "parentId"),
      assignment: this.belongsTo(Assignment, "assignmentId"),
    };
  }

  getChildFiles(): File[] {
    return File.query()
      .where((file: File) => {
        return file.parentId == this.id;
      })
      .get();
  }

  get dir(): boolean {
    return this.type == "dir";
  }

  static getFileForPath(path: string): File | null {
    const files = File.query()
      .where((file: File) => {
        return file.path == path;
      })
      .get();
    return files.length === 1 ? files[0] : null;
  }
}
