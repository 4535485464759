// eslint-disable-next-line @typescript-eslint/no-explicit-any
function tryJsonParse(data: string | undefined): any | undefined {
  try {
    return JSON.parse(data ?? "");
  } catch (e) {
    return undefined;
  }
}

function lookupBoolean(key: string, defaultValue: boolean): boolean {
  const val = process.env[key];
  if (val === undefined) {
    return defaultValue;
  } else {
    return ["true", "1"].includes(val);
  }
}

export default {
  urls: {
    frontend: process.env.VUE_APP_FRONTEND_URL ?? "http://localhost:8080/",
    backend: process.env.VUE_APP_BACKEND_URL ?? "http://localhost:3000/",
    simulator: process.env.VUE_APP_SIMULATOR_URL ?? "http://localhost:3001/",
    phoneHome: process.env.PHONE_HOME_URL ?? "http://app:3000/",
  },
  lti: {
    consumerKey: process.env.LTI_KEY ?? "HlsABZV7NS0iUQ1OyQOm",
    consumerSecret: process.env.LTI_SECRET ?? "R5HvCtCMESHHZFBqY10L",
  },
  xapi: {
    url: process.env.XAPI_URL ?? "https://watershedlrs.com/api/organizations/14791/lrs/",
    username: process.env.XAPI_USERNAME ?? "5fd2829c5e6b4c",
    password: process.env.XAPI_PASSWORD ?? "6b5ebd1b74f5e5",
  },
  simulator: {
    // Secret used by simulator to phone home
    secret: process.env.SIMULATOR_SECRET ?? "f3FaTclinsghnfrJ2n5POQ==",
    ecs: {
      enabled: lookupBoolean("SIMULATOR_ECS_ENABLED", false),
      taskDefinition: process.env.SIMULATOR_ECS_TASK_DEFINITION ?? "fisim-simulator",
      cluster: process.env.SIMULATOR_ECS_CLUSTER ?? "fisim-int-ecs-cluster",
      networkConfiguration: tryJsonParse(process.env.SIMULATOR_ECS_NETWORK_CONFIGURATION) ?? {},
      phoneHomeUrl: process.env.SIMULATOR_ECS_PHONE_HOME_URL ?? "http://app:3000/",
    },
  },
  s3: {
    enabled: lookupBoolean("S3_ENABLED", false),
    accessKeyId: process.env.S3_ACCESS_KEY_ID ?? "",
    secretAccessKey: process.env.S3_SECRECT_ACCESS_KEY ?? "",
    bucket: process.env.S3_BUCKET ?? "",
  },
  logging: {
    level: {
      backend: process.env.LOGGING_LEVEL_BACKEND ?? "debug",
      simulator: process.env.LOGGING_LEVEL_SIMULATOR ?? "debug",
    },
    timestamp: lookupBoolean("LOGGING_TIMESTAMP", false),
    colorize: lookupBoolean("LOGGING_COLORIZE", true),
  },
  jwt: {
    secret: process.env.JWT_SECRET ?? "verysecret",
  },
  scaling: {
    maxPoolSize: parseInt(process.env.SCALING_MAX_POOL_SIZE ?? "") || 20,
    minPoolSize: parseInt(process.env.SCALING_MIN_POOL_SIZE ?? "") || 0,
  },
};
