




import { Component, Vue } from "vue-property-decorator";
import AuthenticationService from "@/frontend/services/AuthenticationService";
import { $tPrefix } from "@/frontend/plugins/i18n";

@Component
export default class Home extends Vue {
  unauthorized = false;

  created(): void {
    if (!this.$route.query.token || !this.$route.query.redirect) {
      this.unauthorized = true;
    } else {
      AuthenticationService.initialize(this.$route.query.token as string);
      window.location.replace(this.$route.query.redirect as string);
    }
  }

  tLocal = $tPrefix("authentication");
}
