

























import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class App extends Vue {
  created(): void {
    if (this.$route.query.locale) {
      this.$i18n.locale = this.$route.query.locale as string;
    }
  }
}
