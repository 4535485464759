export function getFileType(fileName: string): string {
  if (fileName.endsWith(".c") || fileName.endsWith(".h")) {
    return "c";
  } else if (fileName.startsWith("Makefile")) {
    return "mak";
  } else if (fileName.endsWith(".py")) {
    return "pyt";
  } else if (
    fileName.endsWith(".bin") ||
    fileName.endsWith(".enc") ||
    fileName.endsWith(".elf") ||
    fileName.endsWith(".exe") ||
    fileName.endsWith(".o")
  ) {
    return "bin";
  } else {
    return "txt";
  }
}
